/* global dragula Clipboard */

(function () {
	'use strict';

	angular
		.module('common')
		.directive('filterInput', [filterInput])
		.directive('copy', [copy])
		.directive('slidePanel', [slidePanel])
		.directive('toolTip', ['$translate', toolTip])
		.directive('disableDrag', [disableDrag])
		.directive('timedProgress', ['$timeout', timedProgress])
		.directive('circleProgress', [circleProgress])
		.directive('requiredField', ['$timeout', 'utilities', requiredField])
		.directive('focusMe', ['$timeout', focusMe])
		.directive('selectMe', ['$timeout', selectMe])
		.directive('scrollToMe', ['$timeout', scrollToMe])
		.directive('popover', [
			'$window',
			'$document',
			'$timeout',
			'seedcodeCalendar',
			popover,
		])
		.directive('modalDialog', [
			'$document',
			'$timeout',
			'$rootScope',
			modalDialog,
		])
		.directive('nonModalDialog', [
			'$document',
			'$timeout',
			'$rootScope',
			nonModalDialog,
		])
		.directive('mobileModal', [
			'$document',
			'$timeout',
			'$rootScope',
			mobileModal,
		])
		.directive('calendarInfo', ['$timeout', '$rootScope', calendarInfo])
		.directive('messageDialog', [
			'$document',
			'$timeout',
			'$rootScope',
			messageDialog,
		])
		.directive('modalSelect', ['$timeout', modalSelect])
		// .directive('sortable', ['$timeout', 'manageSettings', sortable])
		.directive('helpBeacon', ['$timeout', helpBeacon])
		.directive('autoresize', ['$rootScope', '$timeout', autosizeTextArea])
		.directive('ngclipboard', ['$translate', ngclipboard])
		.directive('progressBar', ['$translate', progressBar])
		.directive('draggable', ['environment', draggable])
		.directive('richTextEditor', [
			'$timeout',
			'environment',
			richTextEditor,
		]);

	function filterInput() {
		return {
			restrict: 'EA',
			scope: {
				type: '@type',
				disableAutoFocus: '@disableAutoFocus',
				disableResize: '@disableResize',
				applyFilter: '=',
			},
			controller: function ($scope) {
				$scope.props = {
					type: $scope.type,
					disableAutoFocus: !!$scope.disableAutoFocus,
					disableResize: !!$scope.disableResize,
					applyFilter: $scope.applyFilter,
				};
			},
			template:
				'<svelte-component component="filterInputFromAngular" props="props"></svelte-component>',
		};
	}

	function copy() {
		return {
			restrict: 'EA',
			scope: {
				content: '@content',
			},
			controller: function ($scope) {
				$scope.props = {
					content: $scope.content,
				};
			},

			template:
				'<svelte-component component="copyFromAngular" props="props"></svelte-component>',
		};
	}

	function slidePanel($timeout) {
		return {
			restrict: 'EA',
			scope: {
				slideTrigger: '=',
			},
			link: function (scope, element, attrs) {
				var triggerValue;
				var leftPanel = element.find('.panel-left');
				var rightPanel = element.find('.panel-right');

				scope.$watch('slideTrigger', function (newValue, oldValue) {
					triggerValue = newValue;
					if (newValue) {
						rightPanel[0].style.visibility = 'visible';
					} else {
						leftPanel[0].style.visibility = 'visible';
					}
				});
				//Watch for the panel to transition. This way we resize after it is hidden or shown
				if (transitionSupported()) {
					leftPanel.on(
						'webkitTransitionEnd msTransitionEnd oTransitionEnd transitionend',
						function () {
							if (triggerValue) {
								leftPanel[0].style.visibility = 'hidden';
							} else {
								rightPanel[0].style.visibility = 'hidden';
							}
						}
					);
				}
			},
		};

		//Feature detection for transitions. We need this specifically for IE9
		function transitionSupported() {
			var s = document.createElement('p').style,
				supportsTransitions =
					'transition' in s ||
					'WebkitTransition' in s ||
					'MozTransition' in s ||
					'msTransition' in s ||
					'OTransition' in s;
			return supportsTransitions;
		}
	}

	function toolTip($translate) {
		return {
			restrict: 'A',
			scope: {
				enable: '=',
			},
			link: function (scope, element, attrs) {
				var closeOnClick = attrs.closeOnClick === 'true' ? true : false;
				//Manage tooltips
				scope.$watch('enable', function (newValue, oldValue) {
					if (newValue) {
						//Enable any tooltips
						initToolTip();
					} else {
						destroyTooltip();
					}
				});

				function initToolTip() {
					var title = attrs.toolTip;
					var preventTranslate = attrs.preventTranslate != 'false';
					var translation = $translate.instant([title]);
					var html = attrs.html == 'true' ? true : false;
					var template = attrs.template ? attrs.template : undefined;
					element.tooltip({
						container: 'body',
						title: preventTranslate ? title : translation[title],
						delay: {
							show: attrs.delay ? Number(attrs.delay) : 1000,
							hide: 0,
						},
						html: html,
						template: template,
					});
					if (closeOnClick) {
						element[0].addEventListener('click', hideTooltip, true);
					}
				}

				//Destroy tooltip when closing popover
				scope.$on('$destroy', function (e) {
					destroyTooltip();

					// Check if a tooltip still exists and remove it so we never leave orphans
					var tooltipElement = $('.tooltip')[0];

					if (tooltipElement) {
						tooltipElement.parentNode.removeChild(tooltipElement);
					}
				});

				function destroyTooltip() {
					if (closeOnClick) {
						element[0].removeEventListener(
							'click',
							hideTooltip,
							true
						);
					}
					element.tooltip('destroy');
				}

				function hideTooltip() {
					element.tooltip('hide');
				}
			},
		};
	}

	function disableDrag() {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				element[0].addEventListener('dragstart', function (e) {
					e.preventDefault();
					return false;
				});
			},
		};
	}

	// function sortable($timeout, manageSettings) {
	// 	return {
	// 		restrict: 'A',
	// 		scope: {
	// 			sortable: '='
	// 		},
	// 		link: function(scope, element, attrs) {
	// 			return;
	// 			var itemHeight;
	// 			var originalIndex;
	// 			var newIndex;
	// 			// if (scope.$last === true) {

	// 			// var parentElement = element.parent();
	// 			var parentElement = element;
	// 			var parentElementHeight = parentElement.height();
	// 			var parentElementPosition = parentElement.position();

	// 			var scrollContainer = parentElement.closest('.sidebar-scroll-content');
	// 			var scrollContainerHeight = scrollContainer.height();
	// 			var scrollContainerPosition = scrollContainer.offset();

	// 			var elementHeight = 40;

	// 			var scrollElement;

	// 			function getIndex(elementPosition) {
	// 				var parentPosition = parentElement.position();
	// 				var index = Math.round((elementPosition - parentPosition.top) / itemHeight);
	// 				return index;
	// 			}
	// 			parentElement.sortable({
	// 				// items:'li',
	// 				start:function (event, ui) {
	// 					itemHeight = ui.item.height();

	// 					scrollElement = scrollContainer.find('.sidebar-scroll-content');
	// 					ui.item.css({'opacity': 0.75});

	// 					var index = getIndex(ui.originalPosition.top);
	// 					originalIndex = index;

	// 					// console.log('scroll', scrollContainer.scrollTop());
	// 					// on start we define where the item is dragged from
	// 					// startIndex = ($(ui.item).index());
	// 				},
	// 				sort: function(event, ui) {
	// 					var scrollElementPosition = scrollElement.position();
	// 					//The top position of our parent element - respects scroll offset
	// 					var parentRelativeTop = (parentElementPosition.top) + scrollElementPosition.top;

	// 					if (ui.offset.top + (elementHeight / 2) >= scrollContainerHeight) {
	// 						scrollContainer.customScrollbar('scrollByY', 5);
	// 						//ui.item.css({'top': (event.pageY + parentElementPosition.top)}); // the element position is including the scrolled position so we need to offset this maybe not use offsetY
	// 					}
	// 					else if (parentRelativeTop < 0 && ui.offset.top - (elementHeight / 2) <= 0) {
	// 						scrollContainer.customScrollbar('scrollByY', -5);
	// 						//ui.item.css({'top': (event.pageY + parentElementPosition.top)});
	// 					}
	// 				},
	// 				stop: function(event, ui) {
	// 					ui.item.css({'opacity': ''});
	// 				},
	// 				update:function (event, ui) {
	// 					var index = getIndex(ui.position.top);
	// 					var moveItem = scope.sortable.splice(originalIndex, 1);
	// 					scope.sortable.splice(index, 0, moveItem[0]);
	// 					scope.sortable.forEach(function(value, index, array1) {
	// 						value.sort = index;
	// 					});
	// 					// manageSettings.updateResource(resourceChanges, 'edit', '', '', function() {alert('done');});
	// 				},
	// 				items: ':not(.list-selector-parent)',
	// 				// cancel: '.list-selector-parent',
	// 				axis:'y',
	// 				scroll: true,
	// 				containment: 'parent',
	// 			});
	// 			// window.setTimeout(function() {
	// 			//   console.log(parentElement.find('li'));
	// 			//   parentElement.sortable('disable');
	// 			// }, 2000);
	// 			// window.setTimeout(function() {
	// 			//   console.log(parentElement.find('li'));
	// 			//   parentElement.sortable('enable');
	// 			// }, 10000);

	// 			// }
	// 		},
	// 	};
	// }

	function timedProgress($timeout) {
		return {
			restrict: 'E',
			scope: {
				onComplete: '&',
			},
			replace: true,
			transclude: true,
			link: function (scope, element, attrs) {
				var duration = Number(attrs.duration) || 10000;
				// var delay = 100;

				var progressTimeout;
				var isRunning;
				var progressPercent = 0;
				var lastTime;

				var progressElement = element.find('.playback-progress-bar');

				scope.progressBarStyle = {
					width: progressPercent + '%',
				};

				attrs.$observe('running', function (running) {
					isRunning = running === 'true' ? true : false;
					if (running) {
						lastTime = Date.now();
						startProgress();
					}
				});

				function startProgress() {
					var lastTimeDuration = Date.now() - lastTime;
					var percentTime = (lastTimeDuration / duration) * 100;

					progressPercent = Math.min(
						progressPercent + percentTime,
						100
					);
					lastTime = Date.now();

					progressElement.width(progressPercent + '%');

					if (progressPercent < 100 && isRunning) {
						requestAnimFrame(startProgress);
					} else if (progressPercent >= 100) {
						if (scope.onComplete) {
							scope.onComplete();
						}
					}
				}

				scope.$on('$destroy', function () {
					isRunning = false;
				});
			},
			template:
				'<div class="event-playback-progress">' +
				'<div class="playback-progress-bar" ng-style="progressBarStyle"></div>' +
				'</div>',
		};
	}

	function circleProgress() {
		return {
			restrict: 'E',
			scope: {},
			replace: true,
			transclude: true,
			link: function (scope, element, attrs) {
				var circleSize = attrs.circleSize;
				var borderWidth = attrs.borderWidth || '5px';
				var transitionDuration = attrs.duration || '.6s';
				//var percentComplete = parseInt(attrs.percentComplete);

				attrs.$observe('percentComplete', function (percentComplete) {
					var rightWidth = Math.min(percentComplete * 2, 100);
					var rightHeight = Math.min(percentComplete * 2, 100);

					var leftWidth =
						percentComplete <= 50 ? 0 : (percentComplete - 50) * 2;
					var leftHeight =
						percentComplete <= 50 ? 0 : (percentComplete - 50) * 2;

					var rightAnimationStyle =
						percentComplete <= 50 ? 'ease' : 'ease-in';

					if (percentComplete >= 100) {
						leftWidth = 100;
						leftHeight = 100;
					}

					//Container style
					scope.circleProgressStyle = {
						// margin: '20px',
						position: 'relative',
						'border-radius': '50%',
						/* opacity: 0.6, */
						width: circleSize,
						height: circleSize,
						'text-align': 'center',
						overflow: 'hidden',
					};

					//Progress bar left style
					scope.progressBarLeftStyle = {
						position: 'absolute',
						width: leftWidth + '%',
						height: leftHeight + '%',
						right: '50%',
						bottom: 0,
						'-webkit-transition':
							'height ' +
							transitionDuration +
							's ' +
							transitionDuration +
							's ease-out',
						'-o-transition':
							'height ' +
							transitionDuration +
							's ' +
							transitionDuration +
							's ease-out',
						transition:
							'height ' +
							transitionDuration +
							's ' +
							transitionDuration +
							's ease-out',
					};

					//Progress bar right style
					scope.progressBarRightStyle = {
						position: 'absolute',
						width: rightWidth + '%',
						height: rightHeight + '%',
						left: '50%',
						top: 0,
						'-webkit-transition':
							'height ' +
							transitionDuration +
							's ' +
							rightAnimationStyle,
						'-o-transition':
							'height ' +
							transitionDuration +
							's ' +
							rightAnimationStyle,
						transition:
							'height ' +
							transitionDuration +
							's ' +
							rightAnimationStyle,
					};

					//Mask style
					scope.innerCircleStyle = {
						position: 'absolute',
						top: borderWidth,
						right: borderWidth,
						bottom: borderWidth,
						left: borderWidth,
						'border-radius': '50%',
						background: 'white',
						'line-height': circleSize,
					};
				});
			},
			template:
				'<div class="circle-progress" ng-style="circleProgressStyle">' +
				'<div class="progress-indicator progress-left" ng-style="progressBarLeftStyle"></div>' +
				'<div class="progress-indicator progress-right" ng-style="progressBarRightStyle"></div>' +
				'<div class="inner-circle" ng-style="innerCircleStyle" ng-transclude></div>' +
				'</div>',
		};
	}

	function requiredField($timeout, utilities) {
		return {
			restrict: 'EA',
			scope: {
				fieldValue: '=ngModel',
			},
			link: function (scope, element, attrs) {
				attrs.$observe('requiredField', function (val) {
					if (val) {
						element[0].addEventListener('blur', checkValue);
					} else {
						element[0].removeEventListener(
							'blur',
							checkValue,
							true
						);
					}
				});

				function checkValue() {
					var fieldValue = scope.fieldValue;
					if (
						typeof fieldValue === 'undefined' ||
						fieldValue === '' ||
						fieldValue === null
					) {
						$timeout(function () {
							element[0].focus();
						}, 0);
						utilities.showMessage(
							'Required field. Please do not leave blank.',
							0,
							8000,
							'error'
						);
					}
				}
			},
		};
	}

	function focusMe($timeout) {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				attrs.$observe('hasFocus', function (value) {
					if (value === 'true') {
						$timeout(function () {
							element[0].focus();
						}, 300);
					}
				});
			},
		};
	}

	function selectMe($timeout) {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				attrs.$observe('hasSelection', function (value) {
					if (value === 'true') {
						$timeout(function () {
							element[0].select();
						}, 0);
					}
				});
			},
		};
	}

	function scrollToMe($timeout) {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				attrs.$observe('hasScroll', function (value) {
					if (value === 'true') {
						$timeout(function () {
							element[0].scrollIntoView();
						}, 310);
					}
				});
			},
		};
	}

	function popover($window, $document, $timeout, seedcodeCalendar) {
		return {
			restrict: 'E',
			scope: {
				show: '=',
				config: '=',
				content: '=',
			},
			replace: true, // Replace with the template below
			transclude: true, // we want to insert custom content inside the directive
			link: function (scope, element, attrs) {
				var config = seedcodeCalendar.get('config');
				var direction;
				var shownWatcher;
				var dimentionWatcher;
				var target = angular.element(scope.config.target);
				var container = scope.config.container
					? angular.element(scope.config.container)
					: angular.element('body');
				var minHorizontalOffset = 10;
				var minVerticalOffset = 10;
				var minEdgeOffset = 10;

				const extraWidth = scope.config.drawerWidth;

				var edgeClasses = [];
				var verticalEdgeClassDistance = 50;
				var horizontalEdgeClassDistance = 220;

				//Check if our clicked target still exists in the DOM. If it doesn't let's not show this popover
				// EDIT: IE10 does not Support .contains() on document so we use document.body
				if (!document.body.contains(target[0])) {
					//Run onHide callback
					if (scope.config && scope.config.onHide) {
						scope.config.onHide(scope.content, scope.config);
					}
					//Destroy the popover
					if (scope.config.destroy) {
						//Remove element from dom
						angular.element(element).remove();
						$timeout(function () {
							scope.$parent.$destroy();
						}, 0);
					} else {
						scope.show = false;
					}
					return;
				}

				container.append(element);

				var windowScroll = $window.scrollY;
				var windowHeight = $window.innerHeight;

				//Container dimentions
				var containerScroll = container.scrollTop();
				var containerWidth = container[0].clientWidth;
				var containerHeight = container[0].clientHeight;
				var containerPositionType = container.css('position');
				var containerPosition = container.offset();
				var containerPositionLeft = containerPosition.left;
				var containerPositionRight =
					containerPositionLeft + containerWidth;
				var containerPositionTop = containerPosition.top;
				var containerPositionBottom =
					containerPositionTop + containerHeight;

				//Adjust for content that is overflowing parent
				var scrollWidth = target[0].scrollWidth;
				var parent = target.parent();

				if (scrollWidth === 0) {
					target = parent;
				}

				//Target element dimentions
				var targetBounds = target[0].getBoundingClientRect();
				var targetWidth = targetBounds.width;
				var parentWidth = target.parent();
				var targetHeight = targetBounds.height;
				var targetPosition = scope.config.useTargetOffset
					? target.offset()
					: target.position();
				var leftPosition =
					targetPosition.left - (scope.config.offsetX || 0);
				var rightPosition =
					targetPosition.left +
					targetWidth +
					(scope.config.offsetX || 0);
				targetPosition.right = targetPosition.left + targetWidth;
				targetPosition.bottom = targetPosition.top + targetHeight;

				var targetOffset = target.offset();
				targetOffset.right = targetOffset.left + targetWidth;
				targetOffset.bottom = targetOffset.top + targetHeight;

				var targetTopAdjusted =
					targetOffset.top - containerPositionTop + containerScroll;

				var targetBottomAdjusted = targetTopAdjusted + targetHeight;

				//Get this elements height and width
				var getElementDimensions = function () {
					return {height: element.height(), width: element.width()};
				};

				dimentionWatcher = scope.$watch(
					getElementDimensions,
					function (newValue, oldValue) {
						//Exit if our popover hasn't taken a size. Hard coded a minimum of 15px as anything that size couldn't possibly be anything usefull
						if (newValue.height < 15 && newValue.width < 15) {
							return;
						}
						//Unbind watcher as we don't want to keep resizing popover if the content is a static height
						if (scope.config.staticHeight) {
							dimentionWatcher();
						}
						var elementWidth =
							scope.config.width && scope.config.width !== 'auto'
								? scope.config.width
								: newValue.width;
						var elementHeight = newValue.height;
						var reserveWidth = scope.config.reserveWidth
							? scope.config.reserveWidth
							: elementWidth;

						var arrow = element.find('.arrow');
						var arrowWidth = arrow.outerWidth(true);
						var arrowHeight = arrow.outerHeight(true);

						var documentPlacementVertical = scope.config.positionY
							? parseInt(scope.config.positionY, 10) -
								elementHeight / 2
							: targetOffset.top -
								(scope.config.anchorTop
									? 1
									: scope.config.anchorBottom
										? elementHeight - targetHeight
										: elementHeight / 2 - targetHeight / 2);
						var originalPlacementVertical;

						var placementVertical;
						var arrowPlacementVertical;

						var placementHorizontal;

						//Initialize the popover style object
						scope.popoverStyle = {};

						if (
							scope.config.width &&
							scope.config.width !== 'auto'
						) {
							scope.popoverStyle.width =
								scope.config.width + 'px';
						}

						//Determine direction
						//Popover placement is a specific point
						if (scope.config.positionX) {
							if (
								!scope.config.direction ||
								scope.config.direction === 'auto'
							) {
								direction =
									scope.config.positionX -
										containerPositionLeft <
									containerWidth / 2
										? 'right'
										: 'left';
							} else {
								direction = scope.config.direction;
							}
							if (direction === 'left') {
								placementHorizontal =
									parseInt(scope.config.positionX, 10) -
									containerPositionLeft -
									elementWidth -
									arrowWidth -
									minHorizontalOffset;
								// scope.popoverStyle.left = parseInt(scope.config.positionX, 10) - containerPositionLeft - elementWidth - arrowWidth - minHorizontalOffset + 'px';
							} else if (direction === 'right') {
								placementHorizontal =
									parseInt(scope.config.positionX, 10) -
									containerPositionLeft +
									arrowWidth +
									minHorizontalOffset;
								// scope.popoverStyle.left = parseInt(scope.config.positionX, 10) - containerPositionLeft + arrowWidth + minHorizontalOffset + 'px';
							}
						}
						//Popover placement is based on an existing element
						else {
							if (
								!scope.config.direction ||
								scope.config.direction === 'auto'
							) {
								direction =
									containerWidth - targetPosition.right >
									targetPosition.left
										? 'right'
										: 'left';
							} else {
								direction = scope.config.direction;
							}

							placementHorizontal =
								direction === 'right'
									? rightPosition
									: leftPosition - elementWidth;
							// scope.popoverStyle.left = direction === 'right' ? targetPosition.right + 'px' : (targetPosition.left - elementWidth) + 'px';

							//Adjust horizontal position if popover won't fit properly
							//Long target element
							if (
								targetWidth > containerWidth / 2 &&
								reserveWidth * 1.5 < containerWidth
							) {
								direction = 'right';
								placementHorizontal = Math.max(
									Math.min(
										containerWidth -
											elementWidth -
											extraWidth -
											minEdgeOffset,
										(scope.config.clickX || leftPosition) +
											arrowWidth
									),
									leftPosition,
									leftPosition +
										arrowWidth +
										minHorizontalOffset
								);
								// scope.popoverStyle.left = Math.max((targetPosition.left + targetWidth) / 2, targetPosition.left + arrowWidth + minHorizontalOffset) + 'px';
							}
							//Popover goes right
							else if (
								direction === 'right' &&
								targetPosition.right + reserveWidth >
									containerWidth
							) {
								placementHorizontal = Math.max(
									containerWidth -
										reserveWidth +
										arrowWidth -
										minEdgeOffset,
									leftPosition +
										arrowWidth +
										minHorizontalOffset -
										minEdgeOffset
								);
								// scope.popoverStyle.left = Math.max(containerWidth - reserveWidth + arrowWidth - minEdgeOffset, targetPosition.left + arrowWidth + minHorizontalOffset - minEdgeOffset) + 'px';
								scope.popoverStyle.marginRight =
									minEdgeOffset + 'px';
							}
							//Popover goes left
							else if (
								direction === 'left' &&
								targetPosition.left < reserveWidth
							) {
								placementHorizontal =
									Math.min(
										reserveWidth + minEdgeOffset,
										rightPosition + minEdgeOffset
									) - elementWidth;
								// scope.popoverStyle.left = (Math.min(reserveWidth + minEdgeOffset, targetPosition.right + minEdgeOffset) - elementWidth) + 'px';
								scope.popoverStyle.marginLeft =
									minEdgeOffset + 'px';
							}
						}

						//Set left position to scope
						scope.popoverStyle.left = placementHorizontal + 'px';

						//Vertical placement
						//Target element spans the vertical view. Most likely on schedule view
						if (
							targetTopAdjusted <= containerScroll &&
							targetBottomAdjusted >=
								containerScroll + containerHeight &&
							!scope.config.positionY
						) {
							placementVertical =
								containerScroll + (scope.config.offsetY || 0);
						} else {
							placementVertical =
								containerScroll +
								documentPlacementVertical -
								containerPositionTop +
								(scope.config.offsetY || 0);
						}

						//Store our original placement so we can compare differences later
						originalPlacementVertical = placementVertical;

						//Vertical placement adjustment for specified vertical values
						if (scope.config.positionY) {
							//Check of the popover is opening below our element and bring it up higher if it is.
							if (
								placementVertical + elementHeight >
								containerHeight + containerScroll
							) {
								placementVertical =
									containerScroll +
									containerHeight -
									elementHeight +
									arrowHeight / 2;
							}
							//Check if the popover is above our element and bring it lower. We use an if rather than else if because we want the default to push lower if the container won't allow the full height of the element
							if (placementVertical <= containerScroll) {
								placementVertical = containerScroll;
							}
						}
						//Vertical placement adjustment when targeting an element
						else {
							//Check of the popover is opening below our element and bring it up higher if it is.
							if (
								placementVertical +
									elementHeight +
									minVerticalOffset >
								containerHeight + containerScroll
							) {
								if (
									targetTopAdjusted >=
									containerScroll +
										containerHeight -
										arrowHeight -
										minVerticalOffset
								) {
									placementVertical =
										containerHeight +
										containerScroll -
										elementHeight +
										arrowHeight;
								} else {
									placementVertical =
										containerHeight + containerScroll >
										targetPosition.bottom
											? containerHeight +
												containerScroll -
												(elementHeight +
													minVerticalOffset)
											: containerHeight +
												containerScroll +
												minVerticalOffset -
												elementHeight;
								}
							}
							//Check if the popover is above our element and bring it lower. We use an if rather than else if because we want the default to push lower if the container won't allow the full height of the element.
							if (
								placementVertical - minVerticalOffset <=
								containerScroll
							) {
								placementVertical =
									containerScroll + minVerticalOffset <
									targetPosition.top
										? containerScroll + minVerticalOffset
										: targetPosition.top < containerScroll
											? containerScroll - arrowHeight
											: containerScroll;
							}
						}

						//Set arrow vertical placement
						//Regular middle of element placement
						if (
							targetTopAdjusted <= placementVertical &&
							targetBottomAdjusted >=
								placementVertical + elementHeight &&
							!scope.config.positionY
						) {
							arrowPlacementVertical =
								elementHeight / 2 - arrowHeight / 2;
						}
						//Arrow needs to go to the top of the element
						else if (
							targetTopAdjusted <= placementVertical &&
							targetBottomAdjusted >= placementVertical &&
							!scope.config.positionY
						) {
							arrowPlacementVertical = arrowHeight / 2;
						} else {
							//This is used if the event spans past the bottom of the scroll area and is more than the arrow height above.
							if (
								targetHeight >
									arrowHeight + minVerticalOffset &&
								targetBottomAdjusted >
									containerScroll + containerHeight &&
								targetTopAdjusted <
									containerScroll + containerHeight &&
								!scope.config.positionY
							) {
								arrowPlacementVertical = Math.min(
									targetTopAdjusted -
										placementVertical +
										arrowHeight / 2,
									elementHeight - arrowHeight
								);
							}
							//Standard arrow position (in the middle of element) where the popover has been adjusted
							else {
								arrowPlacementVertical =
									elementHeight / 2 -
									arrowHeight / 2 +
									(originalPlacementVertical -
										placementVertical);
							}
						}

						if (
							arrowPlacementVertical + arrowHeight >
							elementHeight - arrowHeight / 2
						) {
							placementVertical =
								placementVertical +
								(arrowPlacementVertical +
									arrowHeight -
									elementHeight);
						}

						//Assign edge detection classes
						if (placementHorizontal < horizontalEdgeClassDistance) {
							//Close to left edge
							edgeClasses.push('close-to-left');
						}
						if (
							placementHorizontal + elementWidth >
							containerWidth - horizontalEdgeClassDistance
						) {
							//Close to right edge
							edgeClasses.push('close-to-right');
						}

						if (
							placementVertical - containerScroll <
							verticalEdgeClassDistance
						) {
							//Close to top edge
							edgeClasses.push('close-to-top');
						}
						if (
							placementVertical + elementHeight >
							containerHeight +
								containerScroll -
								verticalEdgeClassDistance
						) {
							//Close to bottom edge
							edgeClasses.push('close-to-bottom');
						}

						scope.edgeClass = edgeClasses.join(' ');

						//Assign and apply styles to element
						scope.direction = direction;
						scope.popoverStyle.top = placementVertical + 'px';

						scope.arrowStyle = {
							top: arrowPlacementVertical + 'px',
						};
					},
					true
				);

				//Run onShown callback
				if (scope.config.onShown) {
					scope.$evalAsync(function () {
						scope.config.onShown(scope.content, scope.config);
					});
				}

				//Close the popover if it is broadcast
				scope.$parent.$on('closePopovers', function () {
					scope.$evalAsync(function () {
						scope.show = false;
					});
				});

				shownWatcher = scope.$watch(
					'show',
					function (newValue, oldValue) {
						//This means that our show value has been set to false. So we are manually closing the popover
						if (!newValue && newValue !== oldValue) {
							closePopover();
						} else if (newValue) {
							//This method may have problems if many popovers are shown at once. We use it because our datepicker buttons were not being detected as inside our element
							element.on('click touchstart', function (e) {
								e.stopPropagation();
							});

							//Add touchstart specifically so anything that might be specifying touch start doesn't double up a popover
							//$document.on('click touchstart', closePopover);
							//We need to use a plain javascript addEventListener here because we need to activate event capture to prevent clicks in certain scenarios
							$(document)[0].addEventListener(
								'mousedown',
								registerCloseListener,
								true
							);
							$(document)[0].addEventListener(
								'touchstart',
								registerCloseListener,
								true
							);
							if (scope.config.destroyOnScroll) {
								$(document)[0].addEventListener(
									'scroll',
									closePopover,
									true
								);
							}
						}
					}
				);

				function registerCloseListener(e) {
					if (e) {
						//Clicked inside popover or on toast (showMessage)
						if (
							element[0] === e.target ||
							element[0].contains(e.target)
						) {
							return;
						}

						// Add listeners for click release
						$(document)[0].addEventListener(
							'mouseup',
							closePopover,
							true
						);
						$(document)[0].addEventListener(
							'touchend',
							closePopover,
							true
						);
					}
				}

				function closePopover(e) {
					var modalElements = document.querySelectorAll('.modal');
					var messageDialog = $('.message-dialog')[0];
					var onHide;

					// Remove existing listners if any exist
					$(document)[0].removeEventListener(
						'mouseup',
						closePopover,
						true
					);
					$(document)[0].removeEventListener(
						'touchend',
						closePopover,
						true
					);

					//exit if we clicked inside the popover
					if (e) {
						//Clicked inside popover or on toast (showMessage)
						if (
							element[0] === e.target ||
							element[0].contains(e.target)
						) {
							return;
						}

						//Clicked on target event
						else if (
							target[0] === e.target ||
							target[0].contains(e.target)
						) {
							//We are not letting the current popover from closing so also prevent a new popover from opening
							config.preventPopover = true;
							window.setTimeout(function () {
								//Clear preventPopover so if the popover is never attempted to open we don't block
								config.preventPopover = false;
							}, 500);
							return;
						} else if (
							messageDialog &&
							messageDialog.contains(e.target)
						) {
							//Don't hide popover if clicking on a toast message
							return;
						} else {
							//Check if there is an event nub - In horizon view only
							var nubContainer = $(e.target).closest(
								'.nub-container'
							)[0];
							// var nubContainer = $(this).closest('.nub-container')[0];
							if (nubContainer) {
								var nubEventID =
									nubContainer.getAttribute('data-id');
								var eventID = target[0].getAttribute('data-id');
								if (eventID === nubEventID) {
									config.preventPopover = true;
									window.setTimeout(function () {
										//Clear preventPopover so if the popover is never attempted to open we don't block
										config.preventPopover = false;
									}, 500);
									return;
								}
							}
						}
						//We don't want to register a document click if a modal is currently open
						for (var i = 0; i < modalElements.length; i++) {
							if (
								modalElements[i] &&
								(e.target === modalElements[i] ||
									modalElements[i].contains(e.target))
							) {
								return;
							}
						}
					}

					//Click throttle created from clicking on new event
					if (config.preventClick) {
						return;
					}

					//Run onHide callback
					if (scope.config && scope.config.onHide) {
						onHide = scope.config.onHide(
							scope.content,
							scope.config,
							e
						);
					}

					if (onHide === false) {
						scope.show = true;
						return;
					}

					//remove click to close listener
					$(document)[0].removeEventListener(
						'mousedown',
						registerCloseListener,
						true
					);
					$(document)[0].removeEventListener(
						'touchstart',
						registerCloseListener,
						true
					);
					$(document)[0].removeEventListener(
						'scroll',
						closePopover,
						true
					);

					//Destroy the popover
					if (scope.config.destroy) {
						//cancel our watchers
						shownWatcher();
						dimentionWatcher();
						//Remove element from dom
						angular.element(element).remove();
						$timeout(function () {
							scope.$parent.$destroy();
						}, 0);
					} else {
						scope.show = false;
					}
					//Run onHidden callback
					if (scope.config.onHidden) {
						scope.$evalAsync(function () {
							scope.config.onHidden(
								scope.content,
								scope.config,
								e
							);
						});
					}
				}
			},
			template:
				"<div class='ng-popover {{direction}} {{config.class}} {{edgeClass}}' ng-show='show' ng-style='popoverStyle'>" +
				"<div class='arrow dbk_popoverArrow' ng-style='arrowStyle'></div>" +
				"<div class='content' ng-transclude></div>" +
				'</div>',
		};
	}

	function modalDialog($document, $timeout, $rootScope) {
		return {
			restrict: 'E',
			scope: {
				show: '=',
				config: '=',
				content: '=',
			},
			replace: true, // Replace with the template below
			transclude: true, // we want to insert custom content inside the directive
			link: function (scope, element, attrs) {
				element.on('click touchstart', function (e) {
					e.stopPropagation();
				});
				var unbindWatcher = $rootScope.$watch(
					'modal.watch',
					function (newValue, oldValue) {
						if (
							scope.config.watch !== newValue &&
							scope.config.id === $rootScope.modal.id
						) {
							unbindWatcher();
							angular.element(element).remove();
							$timeout(function () {
								scope.$parent.$destroy();
							}, 0);
						}
					}
				);

				//Run onShown callback
				if (scope.config.onShown) {
					scope.$evalAsync(function () {
						scope.config.onShown(scope.content, scope.config);
					});
				}

				scope.$watch('show', function () {
					var onHide;
					if (scope.show === false) {
						unbindWatcher();
						//Run onHide callback
						if (scope.config && scope.config.onHide) {
							onHide = scope.config.onHide(
								scope.content,
								scope.config
							);
						}

						if (onHide === false) {
							scope.show = true;
							return;
						}

						scope.$evalAsync(function () {
							scope.backdropFade = false;
						}, 0);

						$timeout(function () {
							angular.element(element).remove();
							scope.$parent.$destroy();
						}, 500);

						//Run onHidden callback
						if (scope.config.onHidden) {
							scope.$evalAsync(function () {
								scope.config.onHidden(
									scope.content,
									scope.config
								);
							}, 0);
						}
					}
				});

				scope.modalStyle = {};
				scope.modalStyle.display = 'block';

				scope.modalDimentions = {};
				scope.modalDimentions.height = scope.config.height
					? scope.config.height + 'px'
					: 'auto';
				scope.modalDimentions.width = scope.config.width
					? scope.config.width + 'px'
					: '400px';

				scope.$evalAsync(function () {
					scope.backdropFade = true;
				}, 0);
			},
			template:
				'<div>' +
				'<div ng-class="{\'in\': backdropFade}" class="modal-backdrop fade"></div>' +
				'<div ng-class="{\'in\': backdropFade}" ng-style="modalStyle" class="modal fade" tabindex="-1" role="dialog">' +
				'<div class="modal-dialog {{config.class}}" ng-style="modalDimentions">' +
				'<div class="modal-content" ng-transclude></div>' +
				'</div>' +
				'</div>' +
				'</div>',
		};
	}

	function nonModalDialog($document, $timeout, $rootScope) {
		return {
			restrict: 'E',
			scope: {
				show: '=',
				config: '=',
				content: '=',
			},
			replace: true, // Replace with the template below
			transclude: true, // we want to insert custom content inside the directive
			link: function (scope, element, attrs) {
				element.on('click touchstart', function (e) {
					e.stopPropagation();
				});
				var unbindWatcher = $rootScope.$watch(
					'modal.watch',
					function (newValue, oldValue) {
						if (
							scope.config.watch !== newValue &&
							scope.config.id === $rootScope.modal.id
						) {
							unbindWatcher();
							angular.element(element).remove();
							$timeout(function () {
								scope.$parent.$destroy();
							}, 0);
						}
					}
				);

				//Run onShown callback
				if (scope.config.onShown) {
					scope.$evalAsync(function () {
						scope.config.onShown(scope.content, scope.config);
					});
				}

				scope.$watch('show', function () {
					var onHide;
					if (scope.show === false) {
						unbindWatcher();
						//Run onHide callback
						if (scope.config && scope.config.onHide) {
							onHide = scope.config.onHide(
								scope.content,
								scope.config
							);
						}

						if (onHide === false) {
							scope.show = true;
							return;
						}

						scope.$evalAsync(function () {
							scope.backdropFade = false;
						}, 0);

						$timeout(function () {
							angular.element(element).remove();
							scope.$parent.$destroy();
						}, 500);

						//Run onHidden callback
						if (scope.config.onHidden) {
							scope.$evalAsync(function () {
								scope.config.onHidden(
									scope.content,
									scope.config
								);
							}, 0);
						}
					}
				});

				scope.modalStyle = {};
				scope.modalStyle.display = 'block';

				scope.modalDimentions = {};
				scope.modalDimentions.height = scope.config.height
					? scope.config.height + 'px'
					: 'auto';
				scope.modalDimentions.width = scope.config.width
					? scope.config.width + 'px'
					: '400px';

				scope.$evalAsync(function () {
					scope.backdropFade = true;
				}, 0);
			},
			template:
				'<div>' +
				'<div ng-class="{\'in\': backdropFade}" ng-style="modalStyle" class="modal non-modal" tabindex="-1" role="dialog">' +
				'<div class="modal-dialog {{config.class}}" ng-style="modalDimentions">' +
				'<div class="modal-content" ng-transclude></div>' +
				'</div>' +
				'</div>' +
				'</div>',
		};
	}
	function mobileModal($document, $timeout, $rootScope) {
		return {
			restrict: 'E',
			scope: {
				show: '=',
				config: '=',
				content: '=',
			},
			replace: true, // Replace with the template below
			transclude: true, // we want to insert custom content inside the directive
			link: function (scope, element, attrs) {
				element.on('click touchstart', function (e) {
					e.stopPropagation();
				});
				var resized;
				var moveParentElement = document.querySelector(
					'#calendar-container'
				);
				var unbindWatcher = $rootScope.$watch(
					'modal.watch',
					function (newValue, oldValue) {
						if (
							scope.config.watch !== newValue &&
							scope.config.id === $rootScope.modal.id
						) {
							moveParentElement.classList.remove(
								'slide-left-under'
							);
							if (resized) {
								triggerRepaint();
							}
							unbindWatcher();
							angular.element(element).remove();
							$timeout(function () {
								scope.$parent.$destroy();
							}, 0);
						}
					}
				);

				//If the window is resized or device rotation occurs let's capture that
				$rootScope.$on('resize', function () {
					resized = true;
				});

				scope.$watch('show', function () {
					var onHide;
					if (scope.show === false) {
						unbindWatcher();
						//Run onHide callback
						if (scope.config && scope.config.onHide) {
							onHide = scope.config.onHide(
								scope.content,
								scope.config
							);
						}

						if (onHide === false) {
							scope.show = true;
							return;
						}

						scope.slideIn = false;
						moveParentElement.classList.remove('slide-left-under');

						$timeout(function () {
							angular.element(element).remove();
							scope.$parent.$destroy();
							if (resized) {
								triggerRepaint();
							}
						}, 500);

						//Run onHidden callback
						if (scope.config.onHidden) {
							scope.$evalAsync(function () {
								scope.config.onHidden(
									scope.content,
									scope.config
								);
							});
						}
					}
				});

				scope.modalStyle = {};

				scope.modalDimentions = {};
				scope.modalDimentions.height = scope.config.height
					? scope.config.height + 'px'
					: 'auto';
				scope.modalDimentions.width = scope.config.width
					? scope.config.width + 'px'
					: 'auto';

				//We need a small timeout here because in some cases the slide animation wasn't smooth
				$timeout(function () {
					scope.slideIn = true;
					moveParentElement.classList.add('slide-left-under');
				}, 50); //Delay here helps give a brief moment to render contents of slide panel so the animation comes out smooth

				function triggerRepaint() {
					//Hacky method to force webkit browsers to repaint. this is necessary because safari fails to render calendar if resized when covered
					$('.calendar').css('display', 'none').height();
					$('.calendar').css('display', 'block');
				}
			},
			template:
				'<div ng-class="{\'in\': slideIn}" ng-style="modalStyle" class="mobile-modal slide-left" tabindex="-1" role="dialog">' +
				'<div class="mobile-modal {{config.class}}" ng-style="modalDimentions">' +
				'<div class="mobile-modal-content" ng-transclude></div>' +
				'</div>' +
				'</div>',
		};
	}

	function calendarInfo($timeout, $rootScope) {
		return {
			restrict: 'E',
			scope: {
				show: '=',
				config: '=',
				content: '=',
			},
			replace: true, // Replace with the template below
			transclude: true, // we want to insert custom content inside the directive
			link: function (scope, element, attrs) {
				element.on('click touchstart', function (e) {
					e.stopPropagation();
				});

				//Broadcast that we are showing calendar info. This way other controllers can watch for it.
				$rootScope.$broadcast('calendarInfo', true);

				var unbindWatcher = $rootScope.$watch(
					'modal.watch',
					function (newValue, oldValue) {
						if (
							scope.config.watch !== newValue &&
							scope.config.id === $rootScope.modal.id
						) {
							unbindWatcher();
							angular.element(element).remove();
							$timeout(function () {
								scope.$parent.$destroy();
							}, 0);
						}
					}
				);

				scope.$watch('show', function () {
					var onHide;
					if (scope.show === false) {
						unbindWatcher();
						//Run onHide callback
						if (scope.config && scope.config.onHide) {
							onHide = scope.config.onHide(
								scope.content,
								scope.config
							);
						}

						if (onHide === false) {
							scope.show = true;
							return;
						}
						//Broadcast that we are showing calendar info. This way other controllers can watch for it.
						$rootScope.$broadcast('calendarInfo', false);

						$timeout(function () {
							angular.element(element).remove();
							scope.$parent.$destroy();
						}, 500);

						//Run onHidden callback
						if (scope.config.onHidden) {
							scope.$evalAsync(function () {
								scope.config.onHidden(
									scope.content,
									scope.config
								);
							}, 0);
						}

						scope.messageStyle = {};

						$timeout(function () {
							scope.messageStyle.opacity = 0;
						}, 0);
					}
				});
			},
			template:
				'<div>' +
				'<div class="calendar-info" ng-style="messageStyle">' +
				'<div class="calendar-info-content" ng-transclude></div>' +
				'</div>',
		};
	}

	function messageDialog($document, $timeout, $rootScope) {
		return {
			restrict: 'E',
			scope: {
				show: '=',
				config: '=',
			},
			replace: true, // Replace with the template below
			transclude: true, // we want to insert custom content inside the directive
			link: function (scope, element, attrs) {
				var hidden;
				var unbindWatcher = $rootScope.$watch(
					'modal.watch',
					function (newValue, oldValue) {
						if (
							scope.config.watch !== newValue &&
							scope.config.id === $rootScope.modal.id
						) {
							hideMessage();
						}
					}
				);
				scope.$watch('show', function () {
					if (scope.show === false) {
						hideMessage();
					}
				});
				var height;
				var delay =
					scope.config.delay === 0 ? 0 : scope.config.delay || 3000;

				scope.messageStyle = {};
				// scope.messageStyle.bottom = "-" + height + "px";

				$timeout(function () {
					// scope.messageStyle.opacity = 1;
					// scope.messageStyle.bottom = 0;
					scope.showMessage = true;
				}, 0);

				if (delay > 0) {
					$timeout(function () {
						hideMessage();
					}, delay);
				}

				//Close the message if we show are hide calendar-info (measure).
				scope.$parent.$on('calendarInfo', function () {
					scope.messageStyle.display = 'none';
					scope.show = false;
				});

				function hideMessage() {
					if (hidden) {
						return;
					}
					hidden = true;
					unbindWatcher();
					var messageElement =
						element[0].querySelector('.message-dialog');
					// height = messageElement.offsetHeight;
					// scope.messageStyle.bottom = "-" + height + "px";
					scope.showMessage = false;

					$timeout(function () {
						angular.element(element).remove();
						scope.$parent.$destroy();
					}, 500);
				}
			},
			template:
				'<div>' +
				'<div class="message-dialog {{config.class}}" ng-class="{\'message-show\': showMessage}" ng-style="messageStyle" tabindex="-1" role="dialog">' +
				'<div ng-click="config.runFunction($event)" class="message-content" ng-transclude></div>' +
				'</div>' +
				'</div>',
		};
	}

	function modalSelect($timeout) {
		return {
			restrict: 'E',
			replace: true,
			transclude: true,
			scope: {
				show: '=',
				position: '@',
			},
			link: function (scope, element, attrs) {
				var height;
				var width;
				var position = scope.position;
				var loaded;

				scope.styles = {};
				scope.contentStyles = {};
				scope.styles.visibility = 'invisible';

				if (position === 'bottom') {
					scope.styles.bottom = 0;
					scope.styles.left = 0;
					scope.styles.right = 0;
				} else if (position === 'top') {
					scope.styles.top = 0;
					scope.styles.left = 0;
					scope.styles.right = 0;
				} else if (position === 'left') {
					scope.styles.top = 0;
					scope.styles.bottom = 0;
					scope.styles.left = 0;
					scope.styles.width = 0;
				} else if (position === 'right') {
					scope.styles.top = 0;
					scope.styles.bottom = 0;
					scope.styles.right = 0;
					scope.styles.width = 0;
				}

				scope.$watch(
					function () {
						return element[0].offsetHeight;
					},
					function (newValue, oldValue) {
						//We only want to run this once
						$timeout(function () {
							if (newValue > 0 && !loaded) {
								height = element[0].offsetHeight;
								width = element[0].offsetWidth;
								if (
									position === 'left' ||
									position === 'right'
								) {
									scope.styles.width = 0;
									scope.contentStyles.height = '100%';
								} else {
									scope.styles.height = 0;
								}
								scope.styles.visibility = 'visible';
								loaded = true;
							}
						}, 0);
					}
				);
				scope.$watch('show', function (newValue, oldValue) {
					if (newValue === oldValue) {
						return;
					}
					if (newValue) {
						if (position === 'left' || position === 'right') {
							scope.styles.width = '100%';
						} else {
							scope.styles.height = height + 'px';
						}
					} else {
						if (position === 'left' || position === 'right') {
							scope.styles.width = '0';
						} else {
							scope.styles.height = '0';
						}
					}
				});
			},
			template:
				'<div class="modal-select {{config.class}}" ng-style="styles">' +
				"<div class=\"modal-select-content\" ng-class=\"{'horizontal': position == 'left' || position === 'right', 'vertical': position == 'top' || position === 'bottom'}\" ng-style=\"contentStyles\" ng-transclude></div>" +
				'</div>',
		};
	}

	function helpBeacon($timeout) {
		let loaded = false;
		return {
			restrict: 'AE',
			scope: {},
			link: function (scope, element, attrs) {
				// Run function to load helpscout beacon code
				if (!loaded) {
					loaded = true;
					!(function (e, t, n) {
						function a() {
							var e = t.getElementsByTagName('script')[0],
								n = t.createElement('script');
							(n.type = 'text/javascript'),
								(n.async = !0),
								(n.src = 'https://beacon-v2.helpscout.net'),
								e.parentNode.insertBefore(n, e);
						}
						if (
							((e.Beacon = n =
								function (t, n, a) {
									e.Beacon.readyQueue.push({
										method: t,
										options: n,
										data: a,
									});
								}),
							(n.readyQueue = []),
							'complete' === t.readyState)
						)
							return a();
						e.attachEvent
							? e.attachEvent('onload', a)
							: e.addEventListener('load', a, !1);
					})(window, document, window.Beacon || function () {});
				}
				var beaconID = '7aaaa18b-d073-4cde-b28d-3f6bfbd8b170';
				// Show the beacon
				window.Beacon('init', beaconID);

				// Watch for the scope destroy event so we can destroy the beacon
				scope.$on('$destroy', function () {
					window.Beacon('destroy');
				});
			},
		};
	}

	function autosizeTextArea($rootScope, $timeout) {
		return {
			restrict: 'AE',
			link: function (scope, element, attrs) {
				var id = element[0].id;

				var maxHeight = Number(attrs.maxHeight) || 500;
				var minHeight = Number(attrs.minHeight);

				var readOnly = element[0].tagName === 'DIV';

				var waitCount = 0;

				var offsetHeight;
				var clientHeight;
				var elementOffset;

				var elementIsVisible;

				var lastContentLength;

				var isLoaded;

				var hasButton = attrs.hasButton;

				//Set initial styles
				element[0].style.overflow = 'hidden';

				//Set $on event listener so we can recieve resize events from other directives
				scope.$on('resize-' + id, function (event, height) {
					getHeights();
				});

				$timeout(function () {
					// Check if the element is visible
					elementIsVisible = !!element[0].offsetParent;
					getHeights();

					if (!isLoaded) {
						loaded();
					}
				}, 0);

				function loaded() {
					//Add event listeners
					if (readOnly) {
						element[0].addEventListener('click', expandDiv);
					} else {
						element[0].addEventListener('input', updateHeight);
						element[0].addEventListener('keyup', updateHeight);
						element[0].addEventListener('focus', focus);
						element[0].addEventListener('blur', blur);
					}
					//Assign an explicit height - Used for transition effects
					if (elementIsVisible) {
						element[0].style.height = offsetHeight + 'px';
					}

					isLoaded = true;

					// Get initial height
					window.setTimeout(function () {
						checkForButtonPadding();
					}, 0);
				}

				function checkForButtonPadding(height) {
					var elementHeight = height
						? height
						: element[0].offsetHeight;
					if (elementHeight <= 38 && hasButton) {
						element[0].style.paddingLeft = '24px';
					} else {
						element[0].style.paddingLeft = null;
					}
				}

				function getHeights() {
					offsetHeight = element[0].offsetHeight;
					clientHeight = element[0].clientHeight;
					elementOffset = offsetHeight - clientHeight;

					if (element[0].style.height === 'auto' && offsetHeight) {
						element[0].style.height = offsetHeight + 'px';
					}
				}

				function resetHeight() {
					element[0].style.overflow = 'hidden';
					element[0].style.height = offsetHeight + 'px';
					checkForButtonPadding(offsetHeight);

					$timeout(function () {
						if (scope.preventContainerScroll === id) {
							scope.preventContainerScroll = false;
						}
						//Reset size of any scroll bars added
						$rootScope.$broadcast('addScroll');
					}, 300);
				}

				function expandDiv() {
					element[0].removeEventListener('click', expandDiv);
					// element[0].addEventListener('click', collapseDiv ); //Disabled this as we don't want to collapse when clicking in our element
					$(document)[0].addEventListener('click', collapseDiv, true);

					element[0].classList.add('animated');
					updateHeight();
					window.setTimeout(function () {
						element[0].classList.remove('animated');
					}, 300);
				}

				function collapseDiv(e) {
					//Return if we are clicking in our element
					if (
						e.target === element[0] ||
						element[0].contains(e.target)
					) {
						return;
					}

					$(document)[0].removeEventListener(
						'click',
						collapseDiv,
						true
					);

					element[0].removeEventListener('click', collapseDiv);
					element[0].addEventListener('click', expandDiv);
					element[0].classList.add('animated');
					element[0].scrollTop = 0;
					resetHeight();
					window.setTimeout(function () {
						element[0].classList.remove('animated');
					}, 300);
				}

				function focus() {
					// If the element was not visible before we will now assume it is visiable to have focus and update the heights
					if (!elementIsVisible) {
						elementIsVisible = true;
						getHeights();
					}

					element[0].classList.add('animated');
					updateHeight();
					window.setTimeout(function () {
						element[0].classList.remove('animated');
					}, 300);
				}

				function blur() {
					element[0].classList.add('animated');
					element[0].scrollTop = 0;
					// wrapped in timeout because onblur fires before click and if a user
					// clicks on a button that moves when the text area collpases it's possible
					// only the click will miss the target due to it moving from the text area resize
					// https://seedcode.atlassian.net/browse/DBK-948
					window.setTimeout(() => {
						resetHeight();
						window.setTimeout(function () {
							element[0].classList.remove('animated');
						}, 300);
					}, 150);
				}

				function updateHeight() {
					//We are already in a field and are just updating the height (probably from editing the contents)
					if (scope.preventContainerScroll === id) {
						applyHeight();
					}
					//This is the first time we have entered the field (probably from clicking into it)
					else {
						scope.preventContainerScroll = id;
						scope.$evalAsync(function () {
							applyHeight();
						});
					}
					$timeout(function () {
						//Reset size of any scroll bars added
						$rootScope.$broadcast('addScroll');
					}, 300);
				}

				function applyHeight() {
					var content = element[0].value;
					var contentLength = content ? content.length : 0;

					//Only revert height when removing characters from field. This prevents hopping and other weird behavior
					if (contentLength < lastContentLength) {
						element[0].style.height = offsetHeight + 'px';
					}
					var scrollHeight = element[0].scrollHeight;
					var elementHeight = Math.min(
						maxHeight,
						scrollHeight + elementOffset
					);

					if (elementHeight < offsetHeight) {
						elementHeight = offsetHeight;
					}

					element[0].style.height = elementHeight + 'px';

					checkForButtonPadding(elementHeight);
					if (scrollHeight > elementHeight) {
						element[0].style.overflow = 'auto';
					} else {
						element[0].style.overflow = 'hidden';
					}
					lastContentLength = contentLength;
				}

				//Remove event listeners on element destroy so we don't have memory leaks
				element.on('$destroy', function () {
					element[0].removeEventListener('input', updateHeight);
					element[0].removeEventListener('keyup', updateHeight);
					element[0].removeEventListener('focus', focus);
					element[0].removeEventListener('blur', blur);
					element[0].removeEventListener('click', blur);
					if (readOnly) {
						$(document)[0].removeEventListener(
							'click',
							collapseDiv,
							true
						);
					}
				});
			},
		};
	}

	function ngclipboard($translate) {
		return {
			restrict: 'A',
			scope: {
				ngclipboardSuccess: '&',
				ngclipboardError: '&',
			},
			link: function (scope, element, attrs) {
				var supported = Clipboard.isSupported();
				var notSupportedTitle = attrs.notSupportedTitle;
				var clipboard = new Clipboard(element[0]);
				var translations;

				if (!supported) {
					translations = $translate.instant([notSupportedTitle]);
					//Use innnerHTML because there may be a span for tanslation already in the button element
					element[0].innerHTML = translations[notSupportedTitle];
				}
				clipboard.on('success', function (e) {
					scope.$apply(function () {
						scope.ngclipboardSuccess({
							e: e,
						});
					});
				});

				clipboard.on('error', function (e) {
					scope.$apply(function () {
						scope.ngclipboardError({
							e: e,
						});
					});
				});

				//Remove event listeners on element destroy so we don't have memory leaks
				element.on('$destroy', function () {
					clipboard.destroy();
				});
			},
		};
	}

	function progressBar($translate) {
		return {
			restrict: 'EA',
			replace: true,
			scope: {
				progress: '=',
			},
			link: function (scope, element, attrs) {},
			template:
				'<div ng-show="progress.show" class="progress-container text-center" style="position: relative; height: 40px;">' +
				'<div ng-show="progress.showComplete" class="progress-complete cssFade" style="position: absolute; top: 6; bottom: 0; left: 0; right: 0;">' +
				'<p class="text-success" style="font-size: 1.4em;"><i class="fa fa-check-circle-o" aria-hidden="true" style="margin-right: 10px;"></i>{{progress.completeMessage}}</p>' +
				'</div>' +
				'<div ng-show="progress.showProgress && !progress.complete" class="progress-pending cssFade" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">' +
				'<p ng-show="progress.message">{{progress.message}}</p>' +
				'<div class="progress" style="height: 6px;">' +
				'<div class="progress-bar" role="progressbar" aria-valuenow="{{progress.value}}" aria-valuemin="0" aria-valuemax="100" ng-style="progress.style"></div>' +
				'</div>' +
				'</div>' +
				'</div>',
		};
	}

	function draggable(environment) {
		var drake = {};
		var containers = {};

		function canDrop(target, source, sibling) {
			var originalGroupingID = source.dataset.grouping;
			var targetGroupingID = target.dataset.grouping;
			var siblingIsFolder = sibling
				? sibling.dataset.isfolder === 'true'
				: false;

			var elementFolderID;
			var elementIsFolder;

			var mirrorElement = document.querySelector('.gu-mirror');

			if (mirrorElement) {
				elementFolderID = mirrorElement.dataset.folderid;
				elementIsFolder = mirrorElement.dataset.isfolder === 'true';
			}

			if (
				// Don't allow dragging to the first or last spot unless in a folder
				(!sibling && !targetGroupingID) ||
				// Don't allow dragging an item to something that doesn't include the drag-item class
				(sibling && !sibling.classList.contains('drag-item')) ||
				// Don't allow dragging an item from outside a folder to inside a folder
				(!elementIsFolder &&
					originalGroupingID !== targetGroupingID &&
					!siblingIsFolder) ||
				// Don't allow dragging a folder inside another folder
				(elementIsFolder && !siblingIsFolder && targetGroupingID) ||
				// Don't allow dragging an item from inside a folder to outside a folder
				(!elementIsFolder && siblingIsFolder && elementFolderID)
			) {
				if (mirrorElement) {
					mirrorElement.style.opacity = 0.25;
				}
				return false;
			} else {
				if (mirrorElement) {
					mirrorElement.style.opacity = 1;
				}
				return true;
			}

			// if (
			// 	(!sibling || sibling.classList.contains('drag-item')) &&
			// 	(originalGroupingID === targetGroupingID && !siblingIsFolder) ||
			// 	(elementIsFolder && !targetGroupingID) ||
			// 	(siblingIsFolder && !elementFolderID)
			// ) {
			// 	if (mirrorElement) {
			// 		mirrorElement.style.opacity = 1;
			// 	}
			// 	return true;
			// }
			// else {
			// 	if (mirrorElement) {
			// 		mirrorElement.style.opacity = 0.25;
			// 	}
			// 	return false;
			// }
		}

		return {
			restrict: 'EA',
			replace: false,
			scope: {
				onDropped: '&',
				onDrag: '&',
				onOut: '&',
			},
			link: function (scope, element, attrs) {
				var onDropCallback;
				var startIndex;
				var instanceType = attrs.instanceType;
				var containerTarget = attrs.containerTarget;

				if (!containers[instanceType]) {
					containers[instanceType] = [];
				}

				containers[instanceType].push(element[0]);

				if (attrs.last !== 'true') {
					return;
				}

				var options = {
					scrollContainers: document.querySelectorAll(
						containerTarget || '.sidebar-scroll-content'
					),
					scrollContentClass: environment.isPhone
						? null
						: '.nano-content',
					scrollbarClass: environment.isPhone ? null : '.nano-pane',
					delay: environment.touchSupported ? 500 : 0,
					scrollTargetPad: 25,

					direction: 'vertical',
					// mirrorContainer: document.querySelector(".sidebar .tabPanel"),
					accepts: function (el, target, source, sibling) {
						if (canDrop(target, source, sibling)) {
							return true;
						}
						return false; // elements can not be dropped by default
					},
					moves: function (el, source, handle, sibling) {
						// Allow dragging of items with a drag class
						if (el.classList.contains('drag-item')) {
							return true;
						}

						return false; // elements are not draggable by default
					},
				};

				// call dragula
				drake[instanceType] = dragula(
					containers[instanceType],
					options
				);

				drake[instanceType].on('dragend', function () {
					// not currently doing anything on drag end
				});

				drake[instanceType].on('drag', function (el, source) {
					if (startIndex !== undefined) {
						return;
					}

					var startContainerIndex = Number(
						source.dataset.container || 0
					);

					var sourceChildren = source.children;
					for (var i = 0; i < sourceChildren.length; i++) {
						if (el === sourceChildren[i]) {
							startIndex = i;
							break;
						}
					}

					var dragResult = scope.onDrag({
						startContainerIndex: startContainerIndex,
						startIndex: startIndex,
					});

					if (dragResult) {
						if (dragResult.onDropCallback) {
							onDropCallback = dragResult.onDropCallback;
						}
						if (dragResult.dragElementMutate) {
							dragResult.dragElementMutate(el);
						}
					}
				});

				drake[instanceType].on(
					'cancel',
					function (el, container, source) {
						startIndex = undefined;
						if (onDropCallback) {
							onDropCallback();
							onDropCallback = null;
						}
					}
				);

				drake[instanceType].on('out', function (el, container, source) {
					var mirrorElement = document.querySelector('.gu-mirror');
					if (mirrorElement) {
						mirrorElement.style.opacity = 0.25;
					}
					if (scope.onOut) {
						scope.onOut({
							startIndex: startIndex,
							element: el,
							source: source,
						});
					}
				});

				// drake[instanceType].on('over', function(el, container, source) {
				// 	var mirrorElement = document.querySelector('.gu-mirror');
				// 	if (mirrorElement) {
				// 		if (canDrop(container, source)) {
				// 			mirrorElement.style.opacity = 1;
				// 		}
				// 		else {
				// 			mirrorElement.style.opacity = 0.25;
				// 		}
				// 	}
				// });

				drake[instanceType].on(
					'drop',
					function (el, target, source, sibling) {
						var targetChildren = target.children;
						var sourceChildren = source.children;

						var startContainerIndex = Number(
							source.dataset.container || 0
						);
						var endContainerIndex = Number(
							target.dataset.container || startContainerIndex
						);

						var endIndex;

						for (var i = 0; i < targetChildren.length; i++) {
							if (el === targetChildren[i]) {
								endIndex = i;
								break;
							}
						}

						scope.onDropped({
							startContainerIndex: startContainerIndex,
							endContainerIndex: endContainerIndex,
							startIndex: startIndex,
							endIndex: endIndex,
						});

						startIndex = undefined;

						if (onDropCallback) {
							onDropCallback();
							onDropCallback = null;
						}
					}
				);

				//Clean up external events on element destroy
				scope.$on('$destroy', function () {
					drake[instanceType].destroy();
				});
			},
		};
	}

	function richTextEditor($timeout, environment) {
		// https://www.cssscript.com/minimal-wysiwyg-editor-pure-javascript-suneditor/
		// https://github.com/JiHong88/SunEditor
		return {
			restrict: 'EA',
			replace: true,
			// This should be called from a modal so don't create a new scope
			// scope: {
			// 	config: "="
			// },
			link: function (scope, element, attrs) {
				var config = scope.$parent.config;
				var content = attrs.content;

				var onChangeCallback = config.onChange;
				var readOnly = attrs.readOnly === 'true';
				var heightOffset = readOnly ? 140 : 240;

				var codeView;

				var editor = SUNEDITOR.create(element[0], {
					// All of the plugins are loaded in the "window.SUNEDITOR" object in dist/suneditor.min.js file
					// Insert options
					// Language global object (default: en)
					// lang: SUNEDITOR_LANG['ko']
					value: content,
					mode: readOnly ? 'balloon' : 'classic', //'classic', 'inline', 'balloon', 'balloon-always'
					height: 'auto',
					resizingBar: false,
					minHeight: environment.isPhone ? 100 : 300,
					maxHeight: window.innerHeight - heightOffset,
					attributesWhitelist: {
						all: 'style', // Apply to all tags
						//  'input': 'checked' // Apply to input tag
					},
					buttonList: [
						['undo', 'redo'],
						['font', 'fontSize', 'formatBlock'],
						// ['paragraphStyle', 'blockquote'],
						['blockquote'],
						['bold', 'underline', 'italic', 'strike'],
						// ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
						['fontColor', 'hiliteColor'],
						// ['fontColor', 'hiliteColor', 'textStyle'],
						['removeFormat'],
						'/', // Line break
						['outdent', 'indent'],
						['align', 'horizontalRule', 'list', 'lineHeight'],
						// ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
						['link' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.

						/** ['imageGallery'] */ // You must add the "imageGalleryUrl".
						// ['fullScreen', 'showBlocks', 'codeView'],
						['codeView'],
						//['preview', 'print'],
						//['save', 'template']
					],
				});

				// Disable if read only
				if (readOnly) {
					editor.disabled();
				}

				editor.toggleCodeView = function (isCodeView, core) {
					codeView = isCodeView;
				};

				// Function when editor content changes
				editor.onChange = function (contents, core) {
					onChangeCallback(contents);
				};

				config.onHide = function () {
					if (codeView) {
						// If we are closing the modal and codeview is up
						// we need to toggle back so changes are saved
						editor.core.toggleCodeView();
					}
				};

				scope.$on('$destroy', function () {
					editor.destroy();
				});
			},
			template: '<textarea class="rich-text-editor"></textarea>',
		};
	}
})();
